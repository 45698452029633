import { Button, Nav, NavDropdown, Navbar } from 'react-bootstrap';

import React from 'react';
import icon from 'images/icon.svg';
import logo from 'images/quotacal.svg';
import { Link } from 'gatsby';

const Header = () => {
    return (
        <Navbar fixed='top' expand="lg" className="navbar">
            <Link to='/'>
                <Navbar.Brand>
                    <img
                        src={icon}
                        style={{ height: '52px' }}
                        alt='icon'
                    />
                    <img
                        src={logo}
                        style={{ height: '30px' }}
                        alt="quotacal"
                    />
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav-autoclose-inside" type='button' data-toggle="collapse" data-target="#navbarnav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto nav">
                    <Link className="LinkColor" to='/features' activeClassName="activeColor">
                        Features
                    </Link>
                    <Link className="LinkColor" to='/solutions' activeClassName="activeColor">
                        Solutions
                    </Link>
                    <Link className="LinkColor" to='/pricing' activeClassName="activeColor">
                        Pricing
                    </Link>
                    <a className="LinkColor" href='https://blog.unomok.com'>Resources</a>
                    <NavDropdown title="Company" id="collasible-nav-dropdown-autoclose-inside">
                        <Link to='/about-us'>
                            <NavDropdown.Item as='button'>About Us</NavDropdown.Item>
                        </Link>
                        <Link to='/contact-us'>
                            <NavDropdown.Item as='button' href='/contact-us'>Contact Us</NavDropdown.Item>
                        </Link>
                    </NavDropdown>
                    <Link className="LinkColor" to='/integration' activeClassName="activeColor">
                        Integration
                    </Link>
                    <Link className="LinkColor" to='/demo'>
                        <Button className="button">Schedule a Demo</Button>
                    </Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    )
};

export default Header;
